export const userInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "John",
    required: true,
  },
  {
    id: "position",
    label: "Position",
    type: "text",
    placeholder: "Optional",
    required: false,
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
    required: true,
  },
  {
    id: "dateOfBirth",
    label: "Date of Birth",
    type: "date",
    required: true,
  },
  {
    id: "nationality",
    label: "Nationality",
    type: "text",
    placeholder: "Your nationality",
    required: true,
  },
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
    required: true,
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "john_doe@gmail.com",
    required: true,
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
    required: true,
  },
  {
    id: "password",
    label: "Password",
    type: "password",
    required: true,
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "USA",
    required: true,
  },
  {
    id: "city",
    label: "City",
    type: "text",
    placeholder: "New York",
    required: true,
  },
];



export const programInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Program....",
  },
  {
    id: "type",
    label: "Type",
    type: "text",
    placeholder: "Type of Program...",
  },
  {
    id: "level",
    label: "Level",
    type: "text",
    placeholder: "Beginner..",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "Location.....",
  },
  {
    id: "participant",
    label: "Participant",
    type: "number",
    placeholder: "Participant",
  },
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "The best Program.....",
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "Description........Details..",
  },
  {
    id: "cheapestPrice",
    label: "Price",
    type: "number",
    placeholder: "100",
  },
];

export const plansInputs = [
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "Plans........",
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "awesome professional ",
  },
    {
    id: "maxParticipant",
    label: "Max Participant",
    type: "number",
    placeholder: "3,6,10,15,20",
  },
];

export const ticketInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "Enter first name",
    required: true,
  },
  {
    id: "middleName",
    label: "Middle Name",
    type: "text",
    placeholder: "Enter middle name",
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Enter last name",
    required: true,
  },
  {
    id: "dateOfBirth",
    label: "Date of Birth",
    type: "date",
    required: true,
  },
  {
    id: "nationality",
    label: "Nationality",
    type: "text",
    placeholder: "Enter nationality",
    required: true,
  },
  {
    id: "customerID",
    label: "Customer ID",
    type: "text",
    placeholder: "Enter customer ID",
    required: true,
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "Enter email",
    required: true,
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "Enter country",
    required: true,
  },
  {
    id: "city",
    label: "City",
    type: "text",
    placeholder: "Enter city",
  },
  {
    id: "Program",
    label: "Program",
    type: "text",
    placeholder: "Enter program",
    required: true,
  },
  {
    id: "plan",
    label: "Plan",
    type: "text",
    placeholder: "Enter plan",
    required: true,
  },
  {
    id: "duration",
    label: "Duration",
    type: "text",
    placeholder: "Enter duration",
    required: true,
  },
  {
    id: "level",
    label: "Level",
    type: "text",
    placeholder: "Enter level",
    required: true,
  },
  {
    id: "phone",
    label: "Phone",
    type: "tel",
    placeholder: "Enter phone number",
    required: true,
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "Enter description",
    required: true,
  },
  {
    id: "trackingID",
    label: "Tracking ID",
    type: "text",
    required: true,
  },
  {
    id: "amount",
    label: "Total Amount",
    type: "number",
    placeholder: "Enter total amount",
    required: true,
  },
];